@use "./variables" as *;

.forgot-password-container {
  form {
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;

    &.go-login {
      text-align: center;

      a {
        text-decoration: none;
        font-size: 20px;
        color: $color-drexel-blue;
        display: inline-block;
        font-weight: $font-medium;

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background: $color-drexel-blue;
          transition: width 0.3s;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
