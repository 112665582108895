@use "./variables" as *;

footer {
  padding: 12px 0;
  background-image: $blue-gradient;

  div {
    font-size: 20px;
    font-weight: $font-regular;
    color: $color-white;
  }
}
