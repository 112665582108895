.highlight {
  background-color: yellow;
  padding: 0.4rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.pad {
  padding: 1rem;
  border: 1px solid #d6d6d6;
  border-radius: 0.5rem;
}
