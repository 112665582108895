.term-card {
    width: 35em;
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding: 1rem;

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    button {
        width: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 1rem;

        button {
            width: 100%;
        }
    }

    .button-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }
}

.layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-evenly;

    div:last-child {
        margin-bottom: .7em;
    }
}

.search-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    gap: 1rem;

    .input-group {
        width: 65%;
    }

    .term-button {
        width: 15%;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.student-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.3rem;
    justify-content: start;
    background-color: #ffffff;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    padding: 0.3rem;
    margin-bottom: 0.5rem;

    p {
        margin: 0;
    }
}

.add-student {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.student-hover:hover {
    background-color: rgba(255, 0, 0, 0.205);
    cursor: pointer;
}

.assignment-card {
    width: 55em;
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding: 1rem;

    .button-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }

    h3 {
        font-size: 1.3rem;
        font-weight: semi-bold;
        margin-bottom: 0.5rem;
    }

    button {
        width: 10rem;
    }
}

.term-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    justify-content: space-between;

    .assignment-layout {
        width: 70%;
    }

    .student-layout {
        width: 30%;
    }
}

.term-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    background-color: #e2e2e28a;
    padding: 0.5rem;
}

.assignment-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 260px);
}

.headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    button {
        width: 10rem;
        height: 2.5rem;
    }
}

.student-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 260px);

    .student-card {
        display: flex;
        flex-direction: column;
        background-color: #f8f9fa;
        border: 1px solid #f8f9fa;
        border-radius: 0.25rem;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
        padding: 0.3rem;
        margin-bottom: 0.5rem;

        .name {
            font-size: 1.3rem;
            font-weight: bold;
        }
    }
}