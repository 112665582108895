@use "./variables" as *;

form {
  margin-bottom: 30px;

  .form-floating {
    input {
      &.form-control {
        font-size: 18px;

        &:not(:placeholder-shown) {
          padding-top: 1.925rem;
        }

        &:focus {
          border-color: $color-light-blue;
          box-shadow: 0 0 0 0.25rem rega($color-light-blue, 0.25);
        }

        &.is-invalid {
          &:focus {
            border-color: var(--bs-form-invalid-border-color);
            box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
          }
        }

        &.is-valid,
        &.is-invalid {
          & ~ i {
            top: 29px;
            right: 25px;
          }
        }
      }
    }

    label {
      font-size: 18px;
      color: $color-dark-gray;
    }

    i {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 0;
      width: 25px;
      height: 18px;
      text-align: center;
      font-size: 18px;
    }

    .invalid-feedback {
      font-size: 16px;
    }
  }

  &.was-validated {
    .form-floating {
      input {
        &.form-control {
          &.is-invalid {
            border-color: var(--bs-form-invalid-border-color);
            background-image: $form-validation-icon-bg;
          }

          & ~ i {
            top: 29px;
            right: 25px;
          }
        }
      }
    }
  }

  button {
    &.form-submit {
      letter-spacing: 1.5px;
      font-weight: $font-bold;
      font-size: 20px;
      text-transform: uppercase;
      padding: 10px 30px;
      border-radius: 0;
      line-height: normal;
      border: none;
      color: $color-white;
      background-image: $blue-gradient;
      margin: 15px 0;
      width: 100%;

      &:hover {
        color: $color-black;
        background-image: $orange-gradient;
      }
    }
  }
}

div {
  &.alert {
    font-size: 18px;
    font-weight: $font-medium;
  }
}
