.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 950px;
    height: 75%;
    overflow: hidden;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    justify-items: center;
    justify-content: center;
}

.title {
    font-size: 20px;
    font-weight: 600;
}

.definitions-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.definitions {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    overflow-y: auto;
}

.definition-label {
    font-size: 18px;
    font-weight: 500;
}

.def-button-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.create-definition {
    overflow-y: auto;
    overflow-x: hidden;
}

.def-buttons {
    display: flex;
    gap: 15px;
}